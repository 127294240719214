export default {
  'Options': 'Opties',
  'Filters': 'Filters',
  'Filters / Options': 'Filters / Opties',
  'Period': 'Periode',
  'Results': 'Resultaten',
  'Number format': 'Nummer weergave',
  'Summary': 'Totalen',
  'Accounting Period': 'Accounting Periode',
  'Currency': 'Valuta',
  'Opening Balance': 'Begin saldo',
  'Payments Amount': 'Betalingen',
  'Advances Amount': 'Voorschotten',
  'Royalty Amount': 'Royalties',
  'Adjustments Amount': 'Correcties',
  'Closing Balance': 'Eind saldo',
  'Select all': 'Selecteer alle',
  'Unselect all': 'Deselecteer alle',
  'Export selected': 'Exporteer selectie',
  'View selected': 'Bekijk selectie',
  'Description': 'Omschrijving',
  'Amount': 'Bedrag',
  'Date': 'Datum',
  'Click on a beneficiary in the table below to view the entries': 'Klik op een begunstigde in de tabel om de balans te bekijken',
  'Beneficiary': 'Begunstigde',
  'Beneficiaries': 'Begunstigden',
  'Beneficiary Code': 'Code Begunstigde',
  'Set password': 'Wachtwoord instellen',
  'Please enter your new password': 'Vul uw nieuwe wachtwoord in',
  'Password': 'Wachtwoord',
  'Repeat password': 'Herhaal wachtwoord',
  'Save': 'Opslaan',
  'You can now login': 'U kunt nu inloggen',
  'Your password has been saved': 'Uw wachtwoord is opgeslagen',
  'An unknown error occurred, please reload the page': 'Er is een onbekende fout opgetreden, ververs de pagina om het opnieuw te proberen',
  'Analytics': 'Analyse',
  'Accounting': 'Statements',
  //'': '',


}