import Vue from 'vue'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations";
import CoreuiVuePro from '@coreui/vue-pro/src/index.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import router from './router'
import axios from "axios"
import moment from 'moment'
import numeral from 'numeral'
import Notifications from 'vue-notification'
import localeDescriptions from '@/locale-descriptions'
import * as validators from '@/validators.js'
import store from './store'
import i18nPlugin from '@/plugins/i18n'
import locale_en from '@/locale/en_GB'
import locale_nl from '@/locale/nl_NL'
import Cookies from 'js-cookie'
import BigNumber from "bignumber.js"
import VueI18n from 'vue-i18n'

Vue.config.productionTip = false

// Sentry
let sentry_dsn = process.env.VUE_APP_SENTRY_DSN
if (!sentry_dsn && process.env.VUE_APP_SENTRY_PROJECT_ID && process.env.VUE_APP_SENTRY_ENVIRONMENT != 'unset') {
  sentry_dsn = 'https://'+process.env.VUE_APP_SENTRY_KEY+'@'+location.host+'/sentry/'+process.env.VUE_APP_SENTRY_PROJECT_ID
}

if (sentry_dsn) {
  Sentry.init({
    Vue,
    dsn: sentry_dsn,
    release: window.SENTRY_RELEASE.id,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ["localhost", 'portal-the-source.local', /^\//],
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: ['warn', 'error', 'debug', 'assert']
      })
    ],
    beforeSend(event) {
      try {
        if (event.exception.values[0].mechanism.handled) {
          console.log(event)
          return event;
        }
        // eslint-disable-next-line no-empty
      } catch (o_O) {}

      return event;
    },
  });
}

// Install CoreUi
Vue.use(CoreuiVuePro)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Notifications
Vue.use(Notifications)

// Localization
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en',
  silentTranslationWarn: true,
  messages: {
    en: locale_en,
    nl: locale_nl,
  }
});

// Register number locales
numeral.register('locale', 'intl', {
  delimiters: {
      thousands: ' ',
      decimal: '.',
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: '€'
  }
});

numeral.register('locale', 'nl', {
  delimiters: {
      thousands: '.',
      decimal  : ','
  },
  abbreviations: {
      thousand : 'k',
      million  : 'mln',
      billion  : 'mrd',
      trillion : 'bln'
  },
  ordinal : function (number) {
      var remainder = number % 100;
      return (number !== 0 && remainder <= 1 || remainder === 8 || remainder >= 20) ? 'ste' : 'de';
  },
  currency: {
      symbol: '€ '
  }
});

// Axios
{
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT
  });

  Vue.prototype.$http = instance
}

Vue.prototype.$validators = validators

Vue.filter('localeDescription', function (value) {
  if (typeof value != 'string') return ''
  
  return localeDescriptions[value.replace('-', '_')]
})

Vue.filter('dateFormat', function (value) {
  if (!value) return '-'
  if (value.date) value = value.date
  return moment(value).format('MMM D, YYYY')
})

Vue.filter('datetimeFormat', function (value) {
  if (!value) return '-'
  if (value.date) value = value.date
  return moment(value).format('DD-MM-YYYY H:mm')
})

Vue.filter('percentage', function (value) {
  return new BigNumber(value).times(100).toFixed() + '%'
})

Vue.filter('yesno', function (value) {
  value = (value === true || value == '1' || value == 1 || value == 'true' || value == 'True')
  return value ? 'Yes' : 'No';
})

Vue.filter('formatAccountingPeriod', function (value) {
  if (value == null) return 'not set'

  value = parseInt(value)
  if (value == 9999) return 'not set'

  let year = Math.floor(value / 100)
  let month = value % 100

  if (year < 90) {
    year += 2000
  } else {
    year += 1900
  }

  if (month == 0) month = 1

  month = String(month).padStart(2, '0')
  year = String(year).padStart(2, '0')
  value = year + month

  return moment(value, "YYYYMM").format('MMM YYYY')
})

//Vue.filter('numberFormat', function (value) {
//  return numeral(value).format('0.[0000]')
//})

Vue.filter('numberFormat', function (value, decimals = 0, locale = 'nl') {
  if (isNaN(value)) return '-'

  numeral.locale(locale)

  return numeral(parseFloat(value)).format('0,0.'+'0'.repeat(decimals))
})

Vue.filter('jsonPretty', function (value) {
  return JSON.stringify(value, null, 2)
})

new Vue({
  render: h => h(App),
  i18n,
  data: {
    me: null,
    adminToken: null,
    loggedIn: false,
    hideMenu: false,
    fieldDefs: null,
    pageSnippets: null,
    crumbs: [],
    countries: {},
    portalInfo: null,
    debugInfoLevel: 0,
    loggingIn: false,
  },
  watch: {
    loggedIn (val) {
      if (val) {
        Sentry.setUser({ email: this.me.EMail });
        
        this.$http.get('release-fields')
        .then(response => {
          this.$data.fieldDefs = response.data
        })
        .catch((error) => {
          console.log(error)
        }) 

        this.$http
          .get('delivery-consent', { params: { filter: { StatusID: 0 } } })
          .then(response => {
            this.$set(this.$data.me, 'deliveryConsent', response.data)
          }).catch(() => {
          })

        this.loadTasks()
       }
    }
  },
  methods: {
    async loadTasks() {
        const response = await this.$http.get('users/me/tasks')
        let tasks = response.data
        this.$set(this.$data.me, 'tasks', tasks)
    },
    scroll (x = 0, y = 0) {
      window.scroll(x, y)
    },
    loadPortal(portalInfo) {
      this.portalInfo = portalInfo

      document.getElementById("favicon").href = portalInfo.favicon;
      document.getElementById("html-title").innerHTML = portalInfo.htmlTitle;

      if (portalInfo.code == 'thso') {
        this.$store.commit('set', ['darkMode', true])
      } else {
        this.$store.commit('set', ['darkMode', false])
      }

      if (portalInfo['providerId'] == '9B79BD1C-55A1-CF4D-AB48-DCAE377E30C2') {
        this.$i18n.locale = 'nl'
      } else {
        this.$i18n.locale = 'en'
      }
   
      this.$http
        .get('countries')
        .then(response => {
          this.countries = {}
          response.data.forEach((item) => {
            this.countries[item.CountryID] = item.Description
          })
        })

      this.$http.get('page-snippets', { params: { providerId: this.portalInfo.providerId, cached: true } })
        .then(response => {
          let snippets = {}
          response.data.forEach(s => {
            snippets[s.Name] = s.Contents
          })

          this.$data.pageSnippets = snippets
        })
        .catch((error) => {
          console.log(error)
        }) 
    },
    afterLogin(response, next = null) {
      if (response.data.user.blocked !== undefined) {
        localStorage.clear()
        
        this.$router.push({ 
          name: 'blocked', 
          params: {
            snippetName: response.data.user.blocked.pageSnippetName,
            html: response.data.user.blocked.html, 
          }
        })

        return
      }

      localStorage.setItem('jwt', response.data.token)
      Cookies.set('jwt', response.data.token)
      this.$root.$data.me = response.data.user
      this.$root.$data.loggedIn = true

      let debugInfoLevel = localStorage.getItem('debugInfoLevel')
      if (debugInfoLevel === null) {
        if (['mail@erikbas.nl', 'ronald@the-source.eu'].includes(this.$root.$data.me.EMail)) {
          debugInfoLevel = 1
        } else {
          debugInfoLevel = 0
        }
      }
      this.$root.$data.debugInfoLevel = Number(debugInfoLevel)

      if (response.data.user.userContextToken) {
        localStorage.setItem('userContextToken', response.data.user.userContextToken)
      }

      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;

      // Check impersonating
      this.$root.$data.adminToken = localStorage.getItem('adminToken')
      
      // Find first provider needing authorization
      let authorizationProvider = false;
      if (this.$root.$data.me.providerData) {
        authorizationProvider = this.$root.$data.me.providerData.find(pd => { return pd.AuthenticationStatus === 'Required' })
      }

      if (authorizationProvider) {
        this.$root.$data.hideMenu = true
        this.$router.push({ 
          name: 'authentication-required',
          params: { providerId: authorizationProvider.ProviderID }
        })
      } else if (typeof next == 'function') {
        next()
      } else if (typeof next == 'string') {
        this.$router.push(next)
      } else {
        this.$router.push({ name: 'dashboard'})
      }
    },
  },
  computed: {
    taskCount() {
      let count = 0
      if (!this.$data.me) return 0
      if (this.$data.me.tasks) {
        count += this.$data.me.tasks.length
      }
      if (this.$data.me.deliveryConsent) {
        count += this.$data.me.deliveryConsent.filter(o => {
          return o.StatusID == 0
        }).length
      }
      return count
    },
    countryOptions () {
      let out = []
      for (const [k, v] of Object.entries(this.countries)) {
        out.push({
          value: k,
          text: v
        })
      }
      return out
    }
  },
  router,
  store,
  icons,
  created () {
    let hostname = localStorage.getItem('forceHostname')
    if (hostname === null) {
      hostname = location.hostname
    }

    this.$http
      .get('portal/info', { params: { hostname: hostname } })
      .then((response) => {
        this.loadPortal(response.data)
      })
  }
}).$mount('#app')
