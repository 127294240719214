import Vue from 'vue'
import Router from 'vue-router'
import * as Sentry from "@sentry/vue";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: '',
      component: () => import('@/containers/DefaultContainer'),
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/views/Dashboard'),
        },
        {
          path: 'support',
          name: 'support',
          component: () => import('@/views/Support'),
        },
        {
          path: 'documentation',
          name: 'documentation',
          component: () => import('@/views/News'),
        },
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/Login'),
        },
        {
          path: 'password-reset/:username/:resetKey',
          name: 'password-reset',
          props: true,
          component: () => import('@/views/PasswordReset'),
        },
        {
          path: 'subproviders',
          name: 'subproviders',
          component: () => import('@/views/SubProviders'),
        },
        {
          path: 'subproviders/:id',
          name: 'subprovider-detail',
          props: true,
          component: () => import('@/views/SubProviderDetail'),
        },
        {
          path: 'portal-users',
          name: 'portal-users',
          component: () => import('@/views/PortalUsers'),
        },
        {
          path: 'portal-users/:id',
          name: 'portal-user-detail',
          props: true,
          component: () => import('@/views/PortalUserDetail'),
        },
        {
          path: 'pagesnippets',
          name: 'pagesnippets',
          component: () => import('@/views/PageSnippetList'),
        },
        {
          path: 'pagesnippets/:id',
          name: 'pagesnippet-detail',
          props: true,
          component: () => import('@/views/PageSnippetDetail'),
        },
        {
          path: 'users',
          name: 'user-search',
          component: () => import('@/views/Users'),
        },
        {
          path: 'users/:id/impersonate',
          name: 'impersonate',
          props: true,
          component: () => import('@/views/UserImpersonate'),
        },
        {
          path: 'users/:id',
          props: true,
          component: () => import('@/containers/UserDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'user-detail',
              component: () => import('@/views/UserDetail'),
            },
            {
              path: 'edit',
              name: 'user-detail-edit',
              component: () => import('@/views/UserDetailEdit'),
            },
          ]
        },        
        {
          path: 'albums',
          name: 'release-search',
          component: () => import('@/views/Releases'),
        },
        {
          path: 'albums/:id',
          props: true,
          component: () => import('@/containers/ReleaseDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'release-detail',
              component: () => import('@/views/ReleaseDetail'),
            },
            {
              path: 'outlets',
              name: 'release-detail-outlets',
              component: () => import('@/views/ReleaseDetailOutlets'),
            },
            {
              path: 'edit',
              name: 'release-detail-edit',
              component: () => import('@/views/ReleaseDetailEdit'),
            },
          ]
        },        
        {
          path: 'tracks',
          name: 'track-search',
          component: () => import('@/views/Tracks'),
        },
        {
          path: 'tracks/:id',
          props: true,
          component: () => import('@/containers/TrackDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'track-detail',
              component: () => import('@/views/TrackDetail'),
            },
          ]
        },        
        /* Disabled
        {
          path: 'playlists/:albumId/track/:id',
          props: true,
          component: () => import('@/containers/TrackDetailContainer'),
          children: [
            {
              path: 'view',
              name: 'release-track-detail',    
              component: () => import('@/views/TrackDetail'),
            },
            {
              path: 'edit',
              name: 'release-track-detail-edit',    
              component: () => import('@/views/TrackDetailEdit'),
            }
          ]
        },*/
        {
          path: 'release-concept-create',
          name: 'release-concept-create',
          component: () => import('@/views/ReleaseConceptCreate.vue'),
        },        
        {
          path: 'release-concepts/:id',
          name: 'release-concepts',
          props: true,
          component: () => import('@/views/ReleaseConcept.vue'),
        },        
        {
          path: 'track-concept-create',
          name: 'track-concept-create',
          component: () => import('@/views/TrackConceptCreate.vue'),
        },        
        {
          path: 'track-concepts/:id',
          name: 'track-concepts',
          props: true,
          component: () => import('@/views/TrackConceptView.vue'),
        },        
        {
          path: 'charly-projects',
          name: 'charly-projects-list',
          component: () => import('@/views/CharlyProjectsList.vue'),
        },
        {
          path: 'charly-tasks',
          name: 'charly-tasks-list',
          component: () => import('@/views/CharlyTasksList.vue'),
        },
        {
          path: 'task-detail/:id',
          props: true,
          component: () => import('@/containers/TaskDetailContainer.vue'),
          children: [
            {
              path: 'overview',
              name: 'task-detail-overview',
              component: () => import('@/views/TaskDetailOverview.vue'),
            },
            {
              path: 'edit',
              name: 'task-detail-edit',
              component: () => import('@/views/TaskDetailEdit.vue'),
            },
            {
              path: 'dataentry',
              name: 'task-detail-dataentry',
              component: () => import('@/views/TaskDetailDataEntry.vue'),
            },
            {
              path: 'attributes',
              name: 'task-detail-attributes',
              component: () => import('@/views/TaskDetailAttributes.vue'),
            },
            {
              path: 'basket',
              name: 'task-detail-basket',
              component: () => import('@/views/TaskDetailBasket.vue'),
            },
            {
              path: 'track-selection',
              name: 'task-detail-basket-track-selection',
              component: () => import('@/views/TaskDetailBasketTrackSelection.vue'),
            },
            {
              path: 'albums/:albumId',
              name: 'task-detail-album',
              props: true,
              component: () => import('@/views/TaskDetailAlbum.vue'),
            },
            {
              path: 'tasks',
              name: 'task-detail-tasks',
              component: () => import('@/views/TaskDetailTasks.vue'),
            },
            {
              path: 'files*',
              name: 'task-detail-files',
              props: true,
              component: () => import('@/views/FileManager.vue'),
            },
          ]
        },
        {
          path: 'trends',
          name: 'trends',
          props: true,
          component: () => import('@/views/Trends.vue'),
        },        
        {
          path: 'royalty-analytics',
          name: 'royalty-analytics',
          props: true,
          component: () => import('@/views/RoyaltyAnalytics.vue'),
        },        
        {
          path: 'royalty-accounting',
          name: 'royalty-accounting',
          props: true,
          component: () => import('@/views/RoyaltyAccounting.vue'),
        },        
        {
          path: 'royalty-accounting-portal',
          name: 'royalty-accounting-portal',
          props: true,
          component: () => import('@/views/RoyaltyAccountingPortal.vue'),
        },        
        {
          path: 'exports',
          name: 'exports',
          props: true,
          component: () => import('@/views/Exports.vue'),
        },        
        {
          path: 'portals',
          name: 'portals',
          props: true,
          component: () => import('@/views/Portals.vue'),
        },        
        {
          path: 'tasks',
          name: 'tasks',
          props: true,
          component: () => import('@/views/Tasks.vue'),
        },        
        {
          path: 'logout',
          name: 'logout',
          component: () => import('@/views/Logout'),
        },
        {
          path: 'royalty-email/:beneficiaryCode',
          name: 'royalty-email',
          props: true,
          component: () => import('@/views/RoyaltyEmailView'),
        },
        {
          path: 'authentication-required/:providerId',
          name: 'authentication-required',
          props: true,
          component: () => import('@/views/AuthenticationRequiredView'),
        },
        {
          path: 'blocked',
          name: 'blocked',
          props: true,
          component: () => import('@/views/Blocked'),
        },
        {
          path: '*',
          name: 'notfound',
          component: () => import('@/views/NotFound'),
        },
      ]
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name == 'login' && to.params.forceLogin === true) {
    return next()
  }

  if (router.app.$data != undefined)
    router.app.$data.crumbs = []

  if ((router.app.$data != undefined) && (router.app.$data.me != null)) {
    return next()
  }

  let jwt = localStorage.getItem('jwt')
  let hashvalue = location.hash.substring(1).split('=', 2)
  let tempAccessToken = null
  if (hashvalue[0] == 'temp-access-token') {
    tempAccessToken = hashvalue[1]
  }

  if (jwt != null || tempAccessToken) {
    let params = {}
    if (tempAccessToken) {
      params['temp-access-token'] = tempAccessToken
    } else if (jwt != null) {
      params['token'] = jwt
    }

    router.app.$http.post('login', params)
      .then(response => {
        router.app.$data.loggingIn = false
        router.app.$root.afterLogin(response, next)
      })
      .catch((error) => {
        router.app.$data.loggingIn = false

        let errorMessage;
        if (error.response && error.response.status == 403) {
          errorMessage = 'Inloggen is niet gelukt. Controleer uw gebruikersnaam en wachtwoord!'
        } else {
          errorMessage = 'Er is een onbekende fout opgetreden. Probeer de pagina opnieuw te laden, en opnieuw in te loggen.'
        }

        next({
          name: 'login',
          params: {
            forceLogin: true,
            nextUrl: to.fullPath,
            errorMessage
          } 
        })

        Sentry.captureException(error)
      })
    
    return
  } else if (['blocked', 'login', 'password-reset'].includes(to.name)) {
    return next()
    
  }

  next({
    name: 'login',
    params: { 
      nextUrl: to.fullPath
    } 
  })
})

export default router