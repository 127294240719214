export default {
  install: (Vue, options) => {
    Vue.prototype.$t = (key) => {
      if (!key) return ''
      let val = key.split('.').reduce((o, i) => {
        if (o) return o[i]
      }, options)
      return val || key
    }
  }
}